/* eslint-disable no-console */
import { getQueryParams } from '../../../../../common/js_helpers/query_string_helpers';
import apiCaller from '../../../../../common/api_caller';
import FormCtaActivator from './form_cta_activate';
import FormCtaField from './form_cta_field';
import FormCtaStorage from './form_cta_storage';
import get from 'lodash.get';
import GoogleAnalyticsService from '../services/google_analytics';
import hubEvents from '../../../../../common/hub_events/hub_events';
import LocalStorageHelper from '../../../../../common/js_helpers/local_storage_helper';
import RecoViewHistory from '../../../../../common/recommendations/reco_view_history';
import SessionHelper from '../../../../../common/js_helpers/session_helper';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uberflip: any;
  }
}

class FormCtaSubmitter {
  private readonly cta: HTMLElement;

  private readonly ctaId: string;

  private readonly ctaService: string;

  private readonly ctaName: string;

  private readonly listId: string;

  private readonly csrfToken: string;

  private readonly expectMoreFields: string;

  private readonly form: HTMLFormElement;

  private readonly formCtaFields: FormCtaField[];

  private readonly activator: FormCtaActivator;

  private readonly queryStrings: { [p: string]: string | null };

  private readonly storage: FormCtaStorage;

  private readonly ga: GoogleAnalyticsService;

  public constructor(
    cta: HTMLElement,
    form: HTMLFormElement,
    formCtaFields: FormCtaField[],
    activator: FormCtaActivator,
  ) {
    this.cta = cta;

    this.ctaId = this.cta.getAttribute('data-id') as string;
    this.ctaService = this.cta.getAttribute('data-integration') as string;
    this.ctaName = this.cta.getAttribute('data-name') as string;
    this.listId = this.cta.getAttribute('data-list-id') as string;
    this.expectMoreFields = this.cta.getAttribute('data-expect-more-fields') as string;

    this.form = form;
    const formElements = form.elements;

    if (formElements) {
      const csrfTokenInput = formElements.namedItem('csrf_token') as HTMLInputElement;
      this.csrfToken = csrfTokenInput.value ?? '';
    }

    this.formCtaFields = formCtaFields;
    this.activator = activator;

    this.queryStrings = getQueryParams();

    this.storage = new FormCtaStorage(this.cta);
    this.ga = new GoogleAnalyticsService();

    this.bindEvents();
  }

  private bindEvents(): void {
    this.form.onsubmit = (event: Event) => {
      event.preventDefault();

      try {
        this.handleSubmit();
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    };
  }

  private handleSubmit = (): void => {
    let pageViews = [];
    const formData: { [key: string]: string | number | null }[] = [];
    const mappedData: { [key: string]: string | number | null } = {};

    // Prepare fields values for request body
    this.getFields().forEach((formCtaField: FormCtaField) => {
      const value = formCtaField.getValue();

      if (formCtaField.mapping) {
        mappedData[formCtaField.mapping] = value;
      }

      formData.push({ id: formCtaField.id, value });
    });

    // Update page views in localStorage
    const itemViewJson = localStorage.getItem(this.ctaService);
    if (itemViewJson) {
      try {
        const itemViews = JSON.parse(itemViewJson);
        if (itemViews && Array.isArray(itemViews.items)) {
          pageViews = itemViews.items;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (parseError) {
        this.resetPageViews();
      }
    }

    const recoViewActivity = new RecoViewHistory().get();

    const previousState = new SessionHelper('previousState');
    const data = {
      col: this.getCurrentCollectionId(),
      csrf_token: this.csrfToken,
      extraParams: this.getExtraParams(),
      formData,
      itm: this.getCurrentItemId(),
      pageViews,
      prevCol: previousState.get('collectionId'),
      prevItm: previousState.get('itemId'),
      ...recoViewActivity,
    };

    this.submitCtaForm(data).then(this.handleSubmitSuccess).catch(this.handleSubmitError);
  };

  private getFields = (): FormCtaField[] =>
    this.formCtaFields.filter((formCtaField: FormCtaField) => !formCtaField.shouldSkipSubmit());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private submitCtaForm = async (data: any) => {
    this.activator.showLoadingPanel();
    const response = await apiCaller.post(`/themes/ajax_submitCtaForm/${this.ctaId}`, data);
    return get(response, 'data.response', []);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleSubmitSuccess = (response: any) => {
    this.activator.hideLoadingPanel();
    this.activator.showSuccessPanel();

    if (this.expectMoreFields === 'false') {
      this.markCtaCompleted();
    }

    this.broadcastCtaSubmittedEvent();
    this.resetPageViews();

    if (response && response.event) {
      this.ga.sendEvent(response.event);
    }

    if (response && response.knownUser) {
      window.uberflip.knownUser = response.knownUser;
    }

    // TODO: BrightInfo Integration tracking details see cta.js
  };

  private markCtaCompleted = (): void => this.storage.setCta();

  private broadcastCtaSubmittedEvent = (): void => {
    const ctaData: { [key: string]: string | number } = {};
    this.getFields().forEach((formCtaField: FormCtaField) => {
      const value = formCtaField.getValue();
      const key = formCtaField.mapping ? formCtaField.mapping : formCtaField.id.toString();
      ctaData[key] = value;
    });

    hubEvents.publish('ctaFormSubmitSuccess', {
      detail: { caller: this.cta, ctaData, ctaId: this.ctaId, ctaName: this.ctaName },
    });
  };

  private handleSubmitError = (error: Error): void => {
    console.error(error);
    this.activator.hideLoadingPanel();
    this.activator.showErrorPanel();
  };

  private resetPageViews = (): void => {
    new LocalStorageHelper(this.ctaService).set({ items: [] });
  };

  private getCurrentCollectionId = (): string => {
    const bodyElement = document.querySelector('body');
    return (bodyElement && bodyElement.getAttribute('data-stream-id')) || 'recent';
  };

  private getCurrentItemId = (): string | null => {
    const bodyElement = document.querySelector('body');
    return bodyElement && bodyElement.getAttribute('data-item-id');
  };

  private getExtraParams = (): object => {
    const conversionItemId = this.getCurrentItemId();

    // Send the query parameters stored from app initialization with cta
    const queryParams = Object.keys(this.queryStrings).map((key) => ({
      id: key,
      value: this.queryStrings[key],
    }));

    return conversionItemId
      ? [{ id: 'uf_conversion_item_id', value: conversionItemId }, ...queryParams]
      : [...queryParams];
  };
}

export default FormCtaSubmitter;
